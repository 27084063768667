import { Injectable } from '@angular/core';
import { TranslationPipe } from '@pipes/translation.pipe';
import * as _ from 'lodash';
import { LocalSettingsService } from '@services/settings.service';
import { ApiService } from '@services/api.service';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MenuUtil {
  currentLang: string;
  menuItems: any = [];
  path: string = 'admin/public/menu';

  constructor(
    private translationPipe: TranslationPipe,
    private apiService: ApiService,
    private settingsService: LocalSettingsService
  ) {
    this.initMenuItems(this.settingsService.getLanguage());
    this.settingsService.currentLanguage.subscribe((lang: string) => {
      this.currentLang = lang;
      this.initMenuItems(lang);
    });
  }

  /**
   * Init navigation menu
   */
  initMenuItems(lang: string, menus?: any) {
    this.menuItems = [];
    let menuData = menus ? menus : JSON.parse(localStorage.getItem('menu')) ?? [];
    menuData.forEach(item => {
      let menuItem: any = {};
      if (item.link !== 'none') {
        menuItem['anchor'] = item.link;
        // menuItem['route'] = item.url;
      } else {
        menuItem['route'] = item.url;
      }
      menuItem['name'] = this.translationPipe.transform(item.title, lang);
      menuItem['icon'] = item.icon;
      if (item.enabled) {
        this.menuItems.push(menuItem);
      }
    });
  }

  /**
   * Get menu items
   */
  getMenuItems() {
    return this.menuItems;
  }

  /**
   * Fetch menu items
   * @param params - Menu section
   */
  fetchMenuItems(params: any) {
    return this.apiService.get(this.path, params);
  }
}
