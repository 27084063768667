<div class="menu-wrapper">
  <cmp-toggle-menu [menuItems]="menuItems" [languages]="availableLangs">
    <cmp-block>
      <!-- <cmp-block class="container-fluid" [@routerTransitionAnimation]="getState(outlet)"> -->
      <router-outlet #outlet="outlet" (activate)="onActivate($event)"></router-outlet>
    </cmp-block>
  </cmp-toggle-menu>
</div>
<cmp-progress class="progress-route"></cmp-progress>

<!-- <cmp-loader></cmp-loader> -->
<cmp-cookies></cmp-cookies>
