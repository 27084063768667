<footer *ngIf="!accepted" id="cookies" class="opaque-bg-cookies">
  <div>
    {{ labels?.text }} <a [routerLink]="'/cookies'" target="_blank">{{ labels?.moreLink }}</a> {{ labels?.text2 }}
  </div>
  <div class="row">
    <div class="mx-auto">
      <cmp-button *ngIf="button" [button]="button" (buttonHandler)="buttonHandler($event)"> </cmp-button>
    </div>
  </div>
</footer>
