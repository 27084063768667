import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
  RouterOutlet,
} from '@angular/router';
import { routerTransitionAnimation } from '@animations/index';
import { DialogContentComponent } from '@components/dialog-content/dialog-content.component';
import { HomeService } from '@modules/home/home.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService, LocalSettingsService, SharedDataService } from '@services/index';
import { LangService } from '@services/lang.service';
import { MenuUtil } from '@services/menu.util';
import { TranslationsService } from '@services/translations.service';
import { CommonUtils } from '@utils/common.utils';
import { UserIdleService } from 'angular-user-idle';
import * as _ from 'lodash';
import { BlockComponent } from './components/block/block.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { ProgressComponent } from './components/progress/progress.component';
import { ToggleMenuComponent } from './components/toggle-menu/toggle-menu.component';

@Component({
  selector: 'cmp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransitionAnimation],
  standalone: true,
  imports: [ToggleMenuComponent, BlockComponent, RouterOutlet, ProgressComponent, CookiesComponent],
})
export class AppComponent implements OnInit {
  menuItems: any[] = [];
  availableLangs: any[] = [];
  translations: any[] = [];
  labels = {};
  lang: string;

  constructor(
    private translateService: TranslateService,
    private settingsService: LocalSettingsService,
    private sharedDataService: SharedDataService,
    private langService: LangService,
    private homeService: HomeService,
    private loaderService: LoaderService,
    private menuUtil: MenuUtil,
    private router: Router,
    private userIdle: UserIdleService,
    private dialog: MatDialog,
    private translationsService: TranslationsService,
    private route: ActivatedRoute
  ) {
    this.router.events.subscribe(event => {
      this.navigationInterceptor(event as RouterEvent);
    });
    
    this.route.queryParams.subscribe((params) => {
      if (params['error']) {
        this.openDialog(this.labels['authDialogContent'], this.labels['authDialogButton'],  this.labels['authDialogTitle']);
        this.router.navigate(['home']);
      }
    });
  }

  ngOnInit() {
    this.loadTranslations();
    this.loadInitialConfig();
    this.startIdleService();

    this.getAvailableLangs();
    this.setLanguage();

    this.menuUtil.initMenuItems(this.settingsService.getLanguage());
    this.menuItems = this.menuUtil.getMenuItems();
    this.settingsService.currentLanguage.subscribe((lang: string) => {
      this.menuUtil.initMenuItems(lang);
      this.menuItems = this.menuUtil.getMenuItems();
    });
  }

  private loadInitialConfig() {
    this.homeService.getProducts().subscribe({
      next: (data) => {
        const sagardobusConfig = data[0];
        this.homeService.setSeasonActive(sagardobusConfig.config.season.active);
        this.homeService.setSeasonMessage(sagardobusConfig.config.season.message);
        this.homeService.setInitializated(true);
      }})
  }

  private loadTranslations() {
    this.translationsService.getTranslations({ pageName: 'main' }).subscribe(items => {
      this.translations = items;
      this.setTranslations();
    });
  }

  private startIdleService(): void {
    this.userIdle.onTimerStart().subscribe(count => {
      this.userIdle.stopWatching();
      if (count != null) this.openIdleDialog();
    });
    this.userIdle.startWatching();
  }

  private openIdleDialog(): void {
    if (this.router.url.startsWith('/home')) {
      let dialogRef = this.openDialog(this.labels['content'], this.labels['button'], this.labels['title']) ;
      dialogRef.afterClosed().subscribe(() => {
        this.sharedDataService.clearAllData();
        window.location.reload();
      });
    } else {
      this.router.navigate(['home'], { fragment: 'home' });
      let dialogRef = this.openDialog(this.labels['content'], this.labels['button'], this.labels['title']);
      dialogRef.afterClosed().subscribe(() => {
        this.sharedDataService.clearAllData();
        this.userIdle.startWatching();
      });
    }
  }

  openDialog(content: string, button: string, title: string): any {
    return this.dialog.open(DialogContentComponent, {
      width: '450px',
      disableClose: true,
      data: {
        content: [content],
        action: button,
        title: title,
      },
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loaderService.show();
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      this.loaderService.hide();
    }
    if (event && event.url && event instanceof NavigationStart) {
      const menuRequest = { section: event.url.indexOf('/sagardobus') !== -1 ? 'SAGARDOBUS' : 'MAIN', sort: 'order:1' };
      let menus: any = [];

      this.menuUtil.fetchMenuItems(menuRequest).subscribe(
        response => {
          menus = response;
        },
        error => {
          console.log(error);
        },
        () => {
          localStorage.setItem('menu', JSON.stringify(menus));
          this.menuUtil.initMenuItems(this.settingsService.getLanguage(), menus);
          this.menuItems = this.menuUtil.getMenuItems();
        }
      );
    }
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  onActivate(event: Event) {
    window.scrollTo(0, 0);
  }

  private getAvailableLangs() {
    this.langService.getLanguages().subscribe(response => {
      this.availableLangs = _.orderBy(response, ['name']);
    });
  }

  /**
   * Return navigator language in short format (es | eu | en)
   */
  private getNavigatorLanguage(): string {
    if (navigator.language.indexOf('es') !== -1) {
      return 'es';
    } else if (navigator.language.indexOf('en') !== -1) {
      return 'en';
    } else if (navigator.language.indexOf('eu') !== -1) {
      return 'eu';
    }

    return 'es';
  }

  /**
   * Set language
   */
  private setLanguage() {
    let domain: string = window.location.hostname.split('.')?.pop() ?? '';

    if (domain === 'eu') {
      // Domain language
      this.lang = 'eu';
    } else if (this.settingsService.getLanguage() && this.settingsService.getLanguage() !== '') {
      // Local Storage language
      this.lang = this.settingsService.getLanguage();
    } else if (navigator.language) {
      // Navigator language
      this.lang = this.getNavigatorLanguage();
    } else {
      // Default language
      this.lang = 'es';
    }

    this.translateService.use(this.lang);

    this.settingsService.currentLanguage.subscribe((lang: string) => {
      this.lang = lang;
      this.setTranslations();
    });
  }

  private setTranslations() {
    
    this.labels['title'] = CommonUtils.getTranslation(this.translations, 'main_idle_title', this.lang);
    this.labels['content'] = CommonUtils.getTranslation(this.translations, 'main_idle_content', this.lang);
    this.labels['button'] = CommonUtils.getTranslation(this.translations, 'main_idle_button', this.lang);
    this.labels['authDialogTitle'] = CommonUtils.getTranslation(this.translations, 'main_auth_dialog_title', this.lang);
    this.labels['authDialogContent'] = CommonUtils.getTranslation(this.translations, 'main_auth_dialog_content', this.lang);
    this.labels['authDialogButton'] = CommonUtils.getTranslation(this.translations, 'main_auth_dialog_button', this.lang);

  }
}
