import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { NgClass, DatePipe } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

@Component({
    selector: 'cmp-date-card',
    templateUrl: './date-card.component.html',
    styleUrls: ['./date-card.component.scss'],
    standalone: true,
    imports: [MatRippleModule, NgClass, DatePipe]
})
export class DateCardComponent implements OnInit, OnChanges {
  /**
   * Data to show
   */
  @Input() data: any;
  /**
   * Flag to check selected date
   */
  @Input() checkedDate: any;
  /**
   * Event to emit selected date
   */
  @Output() selected: EventEmitter<any> = new EventEmitter();
  /**
   * Flag to control checked date (douped for wrapper reasons)
   */
  checked: boolean = false;

  constructor() {}

  ngOnInit() {
    this.checked = this.data._id === this.checkedDate._id;
  }

  ngOnChanges() {
    this.checked = this.data._id === this.checkedDate._id;
  }

  handleClick() {
    this.selected.emit(this.data);
  }

}
