import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const dialog = inject(MatDialog); // Servicio para manejar el modal

  return next(req).pipe(
    catchError((error) => {
      if (error.status === 403 && error.error.code === 'AUTH_ERROR_HEADERS') {
        // Redirigir al usuario a la página de inicio
        dialog.closeAll();
        router.navigate(['/home'], {
            queryParams: { error: 'AUTH_ERROR_HEADERS' },
        });
      }
      return throwError(() => error);
    })
  );
};