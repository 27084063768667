import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

const MENU_URL: string = `${ environment.backend.path + 'admin/public/menu?sort=order:1&section=MAIN'}`;

@Injectable()
export class BootService {

  constructor(private httpClient: HttpClient) {}

  getMenu(): Promise<any> {
    const promise = this.httpClient.get(MENU_URL)
      .toPromise()
      .then(settings => {
        localStorage.setItem('menu', JSON.stringify(settings));
        return settings;
      })
      .catch(error => {
        console.error('UNABLE TO GET MENU ITEMS', error.message);
      });

    return promise;
  }


}
