import { trigger, animate, style, state, query, transition } from '@angular/animations';

export const visibilityAnimation = trigger('visibility', [
  state('true', style({
    transform: 'scale(0)',
    display: 'none'
  })),
  state('false',   style({
    transform: 'scale(1)',
    display: 'block',
  })),
  transition('true => false', animate('300ms ease-in')),
  transition('false => true', animate('300ms ease-out'))
]);
