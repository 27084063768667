<div class="toggle-menu-wrapper">
  <!-- MOBILE -->
  <nav id="sidebar" [class.active]="isCollapsed">
    <div class="sidebar-header" (click)="closeNavBar()">
      <img src="assets/new_assets/main_logo.png" width="160px" alt="Sagardobus" />
    </div>

    <!-- LANGUAGE -->
    <div class="btn-group" role="group" aria-label="Language">
      <img
        *ngFor="let lang of languages"
        class="language-selector"
        [attr.src]="'assets/v3/lang-' + lang?._id + '.svg'"
        [alt]="selectedLanguage"
        (click)="changeLanguage(lang?._id); closeNavBar()"
        [ngClass]="{ 'language-selector--active': selectedLanguage === lang?._id }"
      />
    </div>

    <!-- LINKS -->
    <ul class="list-unstyled components">
      <li *ngFor="let menuItem of menuItems" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a
          *ngIf="!menuItem?.anchor"
          class="menu-item"
          (click)="closeNavBar()"
          [routerLink]="menuItem?.route"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="'active'"
        >
          {{ menuItem?.name }}
        </a>
        <a
          *ngIf="menuItem?.anchor"
          class="menu-item"
          [ngClass]="{ active: isActiveAnchor(menuItem?.anchor) }"
          (click)="closeNavBar()"
          [href]="'home#' + menuItem?.anchor"
        >
          {{ menuItem?.name }}
        </a>
      </li>
    </ul>
  </nav>

  <div class="block-layer" *ngIf="!isCollapsed" (click)="closeNavBar()"></div>

  <!-- CONTENT -->
  <div id="content" [class.active]="isCollapsed">
    <div class="navbar-header">
      <!-- <button type="button" id="sidebarCollapse" class="btn btn-info navbar-btn" > -->
      <div>
        <nav class="navbar navbar-expand-xl navbar-dark bg-dark menu menubar fixed-top">
          <div class="mobile-logo-wrapper" [routerLink]="'/'">
            <img class="mobile-logo" src="assets/new_assets/main_logo_black.png" alt="" />
          </div>
          <button #togglerButton class="navbar-toggler navbar-toggler-right" type="button" (click)="closeNavBar()">
            <mat-icon>menu</mat-icon>
          </button>

          <!-- DESKTOP -->
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto nav-wrapper">
              <div>
                <a class="navbar-brand" [routerLink]="'/'">
                  <img src="assets/new_assets/main_logo_black.png" class="menu-logo" alt="Sagardobus" />
                </a>
              </div>
              <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <a class="nav-link" [routerLink]="'/'" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">Inicio</a>
              </li> -->
              <!-- ROUTING -->
              <div class="nav-items">
                <li
                  *ngFor="let menuItem of menuItems"
                  class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <a
                    *ngIf="!menuItem?.anchor"
                    class="menu-item"
                    [routerLink]="menuItem?.route"
                    [routerLinkActive]="'active'"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    {{ menuItem?.name }}
                  </a>
                  <!-- ANCHOR -->
                  <a
                    *ngIf="menuItem?.anchor"
                    class="menu-item"
                    [ngClass]="{ active: isActiveAnchor(menuItem?.anchor) }"
                    [href]="'home#' + menuItem?.anchor"
                  >
                    {{ menuItem?.name }}
                  </a>
                </li>
              </div>
              <li class="divider"></li>
            </ul>
            <!-- LANGUAGE -->
            <ul class="nav navbar-nav navbar-right language-container">
              <li class="menu-item dropdown">
                <img
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  class="language-selector"
                  [attr.src]="'assets/v3/lang-' + selectedLanguage + '.svg'"
                  [alt]="selectedLanguage"
                />
                <mat-menu #menu="matMenu">
                  <button
                    *ngFor="let lang of languages"
                    mat-menu-item
                    (click)="changeLanguage(lang?._id)"
                    class="to-upper"
                    [ngClass]="{ 'menu-active': selectedLanguage === lang?._id }"
                  >
                    {{ lang?.name }}
                  </button>
                </mat-menu>
              </li>
            </ul>
          </div>
        </nav>
        <!-- </button> -->
      </div>
    </div>

    <ng-content></ng-content>
    <!-- <cmp-footer></cmp-footer> -->
  </div>
</div>
