import { Component, Input, OnInit } from '@angular/core';
import { visibilityAnimation } from '@animations/index';
import { LocalSettingsService } from '@services/index';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgFor, NgClass, NgIf } from '@angular/common';

// import { TranslateService } from '@ngx-translate/core';
const ES_LANGUAGE: string = 'ES';
const EN_LANGUAGE: string = 'EN';
const EU_LANGUAGE: string = 'EU';

@Component({
    selector: 'cmp-toggle-menu',
    templateUrl: './toggle-menu.component.html',
    styleUrls: ['./toggle-menu.component.scss'],
    animations: [visibilityAnimation],
    standalone: true,
    imports: [
        NgFor,
        NgClass,
        NgIf,
        RouterLink,
        RouterLinkActive,
        MatIconModule,
        MatMenuModule,
    ],
})
export class ToggleMenuComponent implements OnInit {
  /**
   * Menu items
   */
  @Input() menuItems: any[];
  /**
   * Languages
   */
  @Input() languages: any[];
  data: any;
  isCollapsed: boolean = true;
  selectedLanguage: string = ES_LANGUAGE;
  currentLanguage: string = 'es';

  constructor(private settingsService: LocalSettingsService) {}

  ngOnInit() {
    this.currentLanguage = this.settingsService.getLanguage();
    this.setCurrentFlag(this.currentLanguage);
  }

  changeLanguage(lang: string) {
    this.settingsService.setLanguage(lang);
    // this.translate.use(lang);
    this.setCurrentFlag(lang);
  }

  closeNavBar() {
    this.isCollapsed = !this.isCollapsed;
  }

  isActiveAnchor(anchor: string): boolean {
    return window.location.href.split('#')[1] === anchor;
  }

  private setCurrentFlag(lang: string) {
    /* switch (lang) {
      case 'es':
        this.selectedLanguage = ES_LANGUAGE;
        break;
      case 'eu':
        this.selectedLanguage = EU_LANGUAGE;
        break;
      case 'en':
        this.selectedLanguage = EN_LANGUAGE;
        break;
      default:
        this.selectedLanguage = ES_LANGUAGE;
        break;
    } */
    if (lang) {
      this.selectedLanguage = lang;
    } else {
      this.selectedLanguage = 'es';
    }
  }
}
