
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { HomeService } from '@modules/home/home.service';
import * as _ from 'lodash';

export const authGuard: CanActivateFn = (route, state) => {
    return new Promise((resolve) => {
        const homeService = inject(HomeService);
        const router = inject(Router);
    
        if (!homeService.getInitializated()) {
            homeService.getProducts().subscribe({
                next: (data) => {
                  const sagardobusConfig = data[0];
                  homeService.setSeasonActive(sagardobusConfig.config.season.active);
                  homeService.setSeasonMessage(sagardobusConfig.config.season.message);
                  homeService.setInitializated(true);
                  if (!_.isUndefined(homeService.getSeasonActive())) {
                    if (!homeService.getSeasonActive()) {
                        router.navigate(['/home']);
                        resolve(false);
                    } 
                }
                resolve(true);
                }})
        } else {
            if (!_.isUndefined(homeService.getSeasonActive())) {
                if (!homeService.getSeasonActive()) {
                    router.navigate(['/home']);
                    resolve(false);
                } 
            }
            resolve(true);
        
        }
    })
  
};