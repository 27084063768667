import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import en from '@angular/common/locales/en';
import es from '@angular/common/locales/es';
import eu from '@angular/common/locales/eu';
import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BootService } from '@services/boot.service';
import * as Hammer from 'hammerjs';

// Routing
// Font Awesome
// UI Material
// UI Components
// Providers
// Ngx-translate
// Calendar
// Boot service
// Locales
// Idle

/**
 * Register locales
 */
registerLocaleData(es);
registerLocaleData(en);
registerLocaleData(eu);

/**
 * Factory for boot service
 */
export function get_menu(bootService: BootService) {
  return () => bootService.getMenu();
}

/**
 * Ignore vertical scroll in hammerjs gestures
 */
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false },
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
