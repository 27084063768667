import { trigger, animate, style, stagger, query, transition } from '@angular/animations';

export const sidreriasAnimation = trigger('sidreriasAnimation', [
  transition('* => *', [
    query('.sidreria-wrapper', style({ transform: 'translateX(-100%)'})),
    query('.sidreria-wrapper',
      stagger('600ms', [
        animate('900ms', style({ transform: 'translateX(0)'}))
    ]))
  ])
]);
