import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';


@Injectable({ providedIn: 'root' })
export class LangService {

  path: string = 'admin/public/lang?enabled=true';

  constructor(private apiService: ApiService) {}

  getLanguages() {
    return this.apiService.get(this.path);
  }

}
