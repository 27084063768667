import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '@services/loader.service';
import { NgClass } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
    selector: 'cmp-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss'],
    standalone: true,
    imports: [MatProgressBarModule, NgClass]
})
export class ProgressComponent implements OnInit, OnDestroy {
  /**
   * Progress color
   */
  color = 'primary';
  /**
   * Mode of the progress ('determinate' | 'indeterminate' | 'buffer' | 'query')
   */
  mode = 'query';
  /**
   * Flag to show or hide progress
   */
  showProgress: boolean = false;
  /**
   * Subscription of the progress bar
   */
  private subscription: Subscription;

  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe((state: any) => {
      this.showProgress = state.show;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
