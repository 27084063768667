// Core
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { TranslationPipe } from '../pipes/translation.pipe';
import { BlockComponent } from './block/block.component';
import { ButtonComponent } from './button/button.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CommentsComponent } from './comments/comments.component';
import { ContactComponent } from './contact/contact.component';
import { CookiesComponent } from './cookies/cookies.component';
import { DateCardComponent } from './date-card/date-card.component';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FooterComponent } from './footer/footer.component';
import { ItineraryStepComponent } from './itinerary/itinerary-step.component';
import { LoaderComponent } from './loader/loader.component';
import { MainComponent } from './main/main.component';
import { ModalComponent } from './modal/modal.component';
import { PlanCardComponent } from './plan-card/plan-card.component';
import { PlanWrapperComponent } from './plan-wrapper/plan-wrapper.component';
import { ProgressComponent } from './progress/progress.component';
import { SidreriaSimpleComponent } from './sidreria-simple/sidreria-simple.component';
import { SidreriaComponent } from './sidreria/sidreria.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { SummaryComponent } from './summary/summary.component';
import { ToggleMenuComponent } from './toggle-menu/toggle-menu.component';
import { WhiteBoxComponent } from './white-box/white-box.component';

// Material Components
// Font Awesome
// Calendar
// UI Components
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatNativeDateModule,
    MatSnackBarModule,
    TranslationPipe,
    ButtonComponent,
    ProgressComponent,
    BlockComponent,
    CarouselComponent,
    ContactComponent,
    CookiesComponent,
    FooterComponent,
    ItineraryStepComponent,
    MainComponent,
    ModalComponent,
    PlanCardComponent,
    PlanWrapperComponent,
    SidreriaComponent,
    SidreriaSimpleComponent,
    SubheaderComponent,
    ToggleMenuComponent,
    WhiteBoxComponent,
    LoaderComponent,
    ErrorDialogComponent,
    DialogContentComponent,
    CalendarComponent,
    SummaryComponent,
    CommentsComponent,
    DateCardComponent,
    FileUploaderComponent,
  ],
  exports: [
    TranslationPipe,
    ButtonComponent,
    ProgressComponent,
    BlockComponent,
    CarouselComponent,
    ContactComponent,
    CookiesComponent,
    FooterComponent,
    ItineraryStepComponent,
    MainComponent,
    ModalComponent,
    PlanCardComponent,
    PlanWrapperComponent,
    SidreriaComponent,
    SidreriaSimpleComponent,
    SubheaderComponent,
    ToggleMenuComponent,
    WhiteBoxComponent,
    LoaderComponent,
    ErrorDialogComponent,
    DialogContentComponent,
    CalendarComponent,
    SummaryComponent,
    CommentsComponent,
    DateCardComponent,
    
  ],
})
export class UIComponentsModule {}
