import { Component, OnInit } from '@angular/core';
import { LocalSettingsService } from '@services/settings.service';
import { TranslationsService } from '@services/translations.service';
import { CommonUtils } from '@utils/common.utils';
import { Buttons } from '../../utils/entities';
import Cookies from 'js-cookie';
import { ButtonComponent } from '../button/button.component';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cmp-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        ButtonComponent,
    ],
})
export class CookiesComponent implements OnInit {
  button: Buttons;
  translations: any[] = [];
  lang: string;
  labels = {};
  accepted: boolean = false;

  constructor(private translationsService: TranslationsService, private settingsService: LocalSettingsService) {}

  ngOnInit() {
    this.lang = this.settingsService.getLanguage();
    this.settingsService.currentLanguage.subscribe((lang: string) => {
      this.lang = lang;
      this.setTranslations();
    });

    this.loadTranslations();
    this.accepted = Cookies.get('sagardobus-cookies-accepted') === 'true';
  }

  private loadTranslations() {
    this.translationsService.getTranslations({ pageName: 'sagardobus_cookies' }).subscribe(items => {
      this.translations = items;
      this.setTranslations();
    });
  }

  private setTranslations() {
    this.labels['text'] = CommonUtils.getTranslation(
      this.translations,
      'sagardobus_cookies_main_description',
      this.lang
    );
    this.labels['moreLink'] = CommonUtils.getTranslation(
      this.translations,
      'sagardobus_cookies_main_description_link',
      this.lang
    );
    this.labels['text2'] = CommonUtils.getTranslation(
      this.translations,
      'sagardobus_cookies_main_description_more_info',
      this.lang
    );
    this.button = {
      text: CommonUtils.getTranslation(this.translations, 'sagardobus_cookies_main_button_accept', this.lang),
      key: 'ok',
    };
  }

  buttonHandler() {
    this.accepted = true;
    Cookies.set('sagardobus-cookies-accepted', 'true', { expires: 1 });
  }
}
